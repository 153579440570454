const tableData = [
    {
        registrationCode: 'CMR-EIDPOC-0001',
        name: 'John Doe',
        qualityAssurance: '1',
        EIDPOCT: '2',
        safety: '3',
        specimentCollection: '2',
        sec5: '1',
        sec6: '1.3',
        sec7: '1.2',
        sec8: '0',
        sec9: '1',
        totalScoreWritten: '50',
        totalPrac: '23',
        performanceWritten: '50',
        totalPerformance: '96',
    },
    // {
    //     registrationCode: 'CMR-EIDPOC-0001',
    //     name: 'John Doe',
    //     qualityAssurance: '1',
    //     EIDPOCT: '2',
    //     safety: '3',
    //     specimentCollection: '2',
    //     sec5: '1',
    //     sec6: '1.3',
    //     sec7: '1.2',
    //     sec8: '0',
    //     sec9: '1',
    //     totalScoreWritten: '50',
    //     totalPrac: '23',
    //     performanceWritten: '50',
    //     totalPerformance: '96',
    // },
    // {
    //     registrationCode: 'CMR-EIDPOC-0001',
    //     name: 'John Doe',
    //     qualityAssurance: '1',
    //     EIDPOCT: '2',
    //     safety: '3',
    //     specimentCollection: '2',
    //     sec5: '1',
    //     sec6: '1.3',
    //     sec7: '1.2',
    //     sec8: '0',
    //     sec9: '1',
    //     totalScoreWritten: '50',
    //     totalPrac: '23',
    //     performanceWritten: '50',
    //     totalPerformance: '96',
    // },
    // {
    //     registrationCode: 'CMR-EIDPOC-0001',
    //     name: 'John Doe',
    //     qualityAssurance: '1',
    //     EIDPOCT: '2',
    //     safety: '3',
    //     specimentCollection: '2',
    //     sec5: '1',
    //     sec6: '1.3',
    //     sec7: '1.2',
    //     sec8: '0',
    //     sec9: '1',
    //     totalScoreWritten: '50',
    //     totalPrac: '23',
    //     performanceWritten: '50',
    //     totalPerformance: '96',
    // },
    // {
    //     registrationCode: 'CMR-EIDPOC-0001',
    //     name: 'John Doe',
    //     qualityAssurance: '1',
    //     EIDPOCT: '2',
    //     safety: '3',
    //     specimentCollection: '2',
    //     sec5: '1',
    //     sec6: '1.3',
    //     sec7: '1.2',
    //     sec8: '0',
    //     sec9: '1',
    //     totalScoreWritten: '50',
    //     totalPrac: '23',
    //     performanceWritten: '50',
    //     totalPerformance: '96',
    // },



];

const fields= [
    { key: "registration_code", sortable: true },
    { key: "name_and_surname", sortable: true },
    { key: "quality_assurance", sortable: true },
    { key: "overview_of_eid_POCT", sortable: true },
    { key: "safety", sortable: true },
    { key: "specimen_collection", sortable: true },
    // { key: "sec5", sortable: true },
    // { key: "sec6", sortable: true },
    // { key: "sec7", sortable: true },
    // { key: "sec8", sortable: true },
    // { key: "sec9", sortable: true },
    { key: "total_score_written", sortable: true },
    { key: "total_practicals", sortable: true },
    { key: "percentage_performance_written", sortable: true },
    { key: "total_percentage_performance", sortable: true },
    { key: "actions", sortable: false },
];

export { tableData, fields };
